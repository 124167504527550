<template>
  <div class="pharmacy">
    <div class="pharmacy-background">
      <img src="/images/pharmacy/bg2.png" />
    </div>

    <div class="date-box">
      <img src="images/pharmacy/ecz.png" class="effect3" alt="e" />
      <span>Nöbetçi Eczaneler</span>
      <strong>{{ day }}</strong>
    </div>

    <div class="main">
      <div class="main-content">
        <div class="item" v-for="p in currentList" :key="p.id">
          <div class="title">
            <div>
              <strong>{{ p.pharmacyName }}</strong>
            </div>
          </div>
          <div class="detail">
            <p>{{ p.address }}</p>
            <span>{{ p.phone }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import dateHelper from "../shared/date-helper";
export default {
  name: "pharmacy",
  props: ["data"],
  data() {
    return {
      destroyed: false,
      pageNo: 0
    };
  },
  created() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (this.destroyed) return;
        this.pageNo += 1;
        if (this.pageNo * 4 >= this.allList.length)
          store.dispatch("setNextPlaylist");
        else this.startTimer();
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.pharmacy;
    },
    allList() {
      return this.model.pharmacyList || [];
    },
    currentList() {
      return this.allList.slice(this.pageNo * 4, this.pageNo * 4 + 4);
    },
    day() {
      return this.allList && this.allList.length
        ? dateHelper.toInputDateFormat(this.allList[0].lastUpdateDate)
        : "";
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>
<style lang="scss">
.pharmacy {
  text-align: center;
}

.pharmacy-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
  }
}

.date-box {
  position: relative;
  margin-top: 32px;
  text-align: left;
  width: auto;
  display: inline-block;
  padding: 14px 64px 14px 212px;
  border-radius: 16px;
  color: #be3d3d;
  background: white;
  height: 138px;
  border-radius: 138px;
  font-family: Montserrat-Bold, tahoma;

  img {
    position: absolute;
    left: 46px;
    top: -16px;
  }

  span {
    display: block;
    font-size: 56px;
    line-height: 56px;
    text-transform: uppercase;
  }

  strong {
    display: block;
    font-size: 50px;
    line-height: 48px;
    letter-spacing: 2px;
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
  }
}

/*DETAIL*/
.main {
  position: absolute;
  left: 40px;
  right: 40px;
  top: 212px;
}

.main-content {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: inline-block;
    padding: 14px 100px;
    margin: 0 auto;
    max-width: 920px;
    text-align: center;
  }

  .title {
    display: inline-block;
    font-size: 48px;
    min-width: 420px;
    line-height: 64px;
    font-weight: normal;
    width: 100%;
    margin: 0 auto;
    background: #be3d3d;
    color: white;
    border: solid 3px white;
    text-align: center;
    padding: 7.6px 12px;
    max-width: 100%;
    max-height: 85px;
    overflow: hidden;

    &>div {
      position: relative;
      display: -webkit-box;
      max-height: 72px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }

    strong {
      font-family: Montserrat-SemiBold, tahoma;
      font-weight: normal;
    }
  }

  .detail {
    background: url("/images/pharmacy/card-bg.png") center no-repeat;
    background-size: 100% 102%;
    color: white;
    border: solid 4px white;
    margin-top: -3px;
    text-align: center;
    padding: 16px;
    min-width: 684px;

    p {
      display: block;
      font-size: 38px;
      line-height: 47px;
      font-weight: normal;
      margin: 0 auto 6px;
      max-width: 98%;
      max-height: 188px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      font-size: 53px;
      font-weight: normal;
      font-family: Montserrat-Bold, tahoma;
      color: #b73636;
    }
  }
}</style>
